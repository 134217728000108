import React from 'react';
import{
    Card, CardBody, CardTitle, CardFooter, Button
} from "reactstrap";
import '../../assets/css/custom-kit.css';
import { Envelope } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";
import useWindowDimensions from '../../gc-comps/utility/useWindowDimensions.js';

const lessonSelectInstructor = new Event('LESSON_SELECT_INSTRUCTOR');

function InstructorListCardAltOne(props){
    var instruments = props && props.instData && props.instData.instruments ? props.instData.instruments.map(instruments => instruments.key) : [];
    var instnameInd = props && props.instData && props.instData.name ? props.instData.name.toLowerCase().replace(' ', '-') : '';
    const { height, width } = useWindowDimensions();
    const[toggleContactBtn, setToggleContactBtn] = React.useState(false);

    React.useEffect(() => {
        if(width > 767 && width < 1300){
            setToggleContactBtn(true);
        }else{
            setToggleContactBtn(false);
        }
    }, [width]);

    const history = useHistory();

    const routeChange = (e, id) =>{ 
          let path = `instructor-profile?id=${id}`; 
          history.push(path);
        }
    const storeAddress = props.instData.storeaddress2 ? `${props.instData.storeaddress1} ${props.instData.storeaddress2}` : `${props.instData.storeaddress1}`;
    const _30price = props.instData['thirtyprice'] ? `$${props.instData['thirtyprice']}.00` : null;
    const _60price = props.instData['sixtyprice'] ? `$${props.instData['sixtyprice']}.00` : null;

    const hasImage = props.instData.imagename && (props.instData.imagename !== 'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png');
    const hideInstructorName = !props.instData.shortbio && !hasImage;

    return(
        <Card className="card-profile">
                  <CardBody>
                    <div className="card-avatar">
                      {!hideInstructorName ?
                      <a href="/instructor-profile" onClick={(e) => {e.preventDefault();routeChange(e, props.instData.instructorId)}}>
                        {props.instData && props.instData.imagename && (props.instData.imagename !== 'https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png') ?  <img
                          alt="avatar"
                          src={props.instData.imagename}
                        /> : <img
                                   alt="avatar"
                                   src="https://studentpassimages.s3-us-west-2.amazonaws.com/imageedit_10_9652185332.jpeg"
                                 />

                        }
                      </a> : <img
                                   alt="avatar"
                                   src="https://studentpassimages.s3-us-west-2.amazonaws.com/imageedit_10_9652185332.jpeg"
                      /> }
                    </div>
                    <div className="card-profile_prices">
                          <div className="lesson-price-panel --preview">
                            <div className="lesson-price-panel_row">
                              <div className="lesson-price-panel_col">
                                <p className="panel_bullet-item">{_30price ? "(4) 30 Min" : " "}</p>
                              </div>
                              <div className="lesson-price-panel_col">
                                <p className="lesson-price-panel_price">{_30price ? _30price : " "}</p>
                              </div>
                            </div>
                            <div className="lesson-price-panel_row">
                              <div className="lesson-price-panel_col">
                                <p className="panel_bullet-item">{_60price ? "(4) 60 Min" : " "}</p>
                              </div>
                              <div className="lesson-price-panel_col">
                                <p className="lesson-price-panel_price">{_60price ? _60price : " "}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!hideInstructorName ?
                            <a href="/instructor-profile" onClick={(e) => {e.preventDefault();routeChange(e, props.instData.instructorId)}}>
                            <h5 className="card-category inst-list-name">{hideInstructorName ? 'Guitar Center Instructor':props.instData.name}</h5>
                            <div className="card-profile_location">
                            <div className="card-profile_location_icon"></div>
                            <p style={{fontWeight: '500'}}>{`Guitar Center ${props.instData.storecity} #${props.instData.storeNumber}`}<br/>
                            {storeAddress}<br/>{`${props.instData.storecity}, ${props.instData.storestate}`}<br/>
                            Distance : {parseFloat(props.instData.fields.distance).toFixed(1)} mi</p>
                                </div>
                            </a>
                            :
                            <>
                              <h5 className="card-category inst-list-name">{hideInstructorName ? 'Guitar Center Instructor':props.instData.name}</h5>
                              <div className="card-profile_location">
                              <div className="card-profile_location_icon"></div>
                              <p style={{fontWeight: '500'}}>{`Guitar Center ${props.instData.storecity} #${props.instData.storeNumber}`}<br/>
                              {storeAddress}<br/>{`${props.instData.storecity}, ${props.instData.storestate}`}<br/>
                              Distance : {parseFloat(props.instData.fields.distance).toFixed(1)} mi</p>
                                  </div>
                            </>
                        }
                        <div className="shorten-bio">
                            <p className="card-description color-dark my-auto">
                                {props.instData.shortbio ? props.instData.shortbio.replace(/<[^>]+>/g, '').replace(/\\\"/g, "\"").replace(/&rsquo;/g, "'").replace(/ \\\\ /g, "/") : null}
                            </p>
                        </div>
                        
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                        className={`btn btn-secondary pull-left ${toggleContactBtn? "ind-btn-tgl" : ""}`}
                        color="white"
                        href="/instructor-profile"
                        name={props.instData.name}
                        style={{margin:'auto'}}
                        onClick={(e) => {e.preventDefault(); props.contactModalTrigger(e, props.instData)}}
                        >
                        Contact Me
                    </Button>
                    <Button
                        className={`btn btn-primary pull-right ${toggleContactBtn? "ind-btn-msg-tgl" : ""}`}
                        color="danger"
                        href="!#"
                        name={props.instData.name}
                        onClick={(e) => {e.preventDefault(); document.dispatchEvent(lessonSelectInstructor); props.bookModalTrigger(e, props.instData)}}
                        >
                        Book Now
                    </Button>
                  </CardFooter>
                </Card>
    );
}

export default InstructorListCardAltOne;