import React from "react";

function SampleCartLanding(props){

    let landingHeader = React.createRef();
    
    var site = localStorage.getItem('siteSource');

    var logoImg = require("../../assets/img/sample-lesson-cart.png");
    if(site && site === 'ma'){
      logoImg = require("../../assets/img/ma-mock-up-cart-2.png");
    }

    return(
        <div className="header-2">
            <div className="page-header"
            ref={landingHeader}
            style={{
                backgroundImage:
                    `url(${logoImg})`,
                }}></div>
        </div>
    )

}

export default SampleCartLanding;