import axios from "axios";
import React from "react";
import {
  Button,
  Col,
  Collapse,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { searchProxyAPIKey, searchProxyAPIUrl } from "../../config";
import CustomCalendar from "../../gc-comps/fragments/CustomCalendar.js";
import { validateFormFields } from "../../gc-comps/utility/CommonHandlers.js";
var moment = require("moment");

const lessonAddToCart = new Event("LESSON_ADD_TO_CART");
const lessonAcceptTerms = new Event("LESSON_ACCEPT_TERMS");
const lessonClosedTerms = new Event("LESSON_CLOSED_TERMS");

function BookNowModal(props) {
  const instrumentSet = props.instructor.instruments.map((value, index) => {
    return { value: value, label: value };
  });
  var selectedInstrument = {};
  var vUserSelections = JSON.parse(localStorage.getItem("userSelections"));
  if (
    vUserSelections &&
    vUserSelections.instrument &&
    vUserSelections.instrument.length > 0
  ) {
    var instumentsSel = vUserSelections.instrument;
    if (instumentsSel.length === 1 && instumentsSel[0] !== "Not sure") {
      selectedInstrument = { value: instumentsSel[0], label: instumentsSel[0] };
    }
  }
  const _30price = parseInt(props.instructor["thirtyprice"]);
  const _60price = parseInt(props.instructor["sixtyprice"]);
  var defaultMode =
    props.instructor.instructorMode &&
    props.instructor.instructorMode.length > 0
      ? props.instructor.instructorMode[0].toLowerCase()
      : "in-store";
  if (vUserSelections && vUserSelections.lessonMode) {
    var userSelMode = vUserSelections.lessonMode;
    if (!Array.isArray(userSelMode)) {
      userSelMode = [userSelMode];
    }

    if (userSelMode.length === 1) {
      defaultMode = userSelMode[0].toLowerCase();
    }
  }

  const priceMap = {
    30: _30price,
    60: _60price,
  };

  const [firstNameValid, setFirstNameValid] = React.useState("");
  const [lastNameValid, setLastNameValid] = React.useState("");
  const [emailValid, setEmailValid] = React.useState("");

  const [bookStep, setBookStep] = React.useState(2);
  const [instrument, setInstrument] = React.useState(
    selectedInstrument && selectedInstrument.value
      ? selectedInstrument
      : instrumentSet[0]
  );
  const [duration, setDuration] = React.useState("30");
  const [count, setCount] = React.useState(4);
  const [price, setPrice] = React.useState(priceMap[duration]);
  const [timeSlot, setTimeSlot] = React.useState("");
  const [time, setTime] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [finalAction, setFinalAction] = React.useState("Schedule");
  const [instInvalid, setInstInvalid] = React.useState(false);
  const [scheduleNow, setScheduleNow] = React.useState("yes");
  const [scheduleModal, setScheduleModal] = React.useState(false);
  const [contactInstructor, setContactInstructor] = React.useState(
    props.instructor
  );
  const [lessonType, setLessonType] = React.useState(defaultMode);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [custEmail, setCustEmail] = React.useState("");
  const [parentName, setParentName] = React.useState("");
  const [formValid, setFormValid] = React.useState(true);
  const [studentTaking, setStudentTaking] = React.useState("myself");
  const [termsModal, setTermsModal] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [timeUnavailable, setTimeUnavailable] = React.useState(false);

  React.useEffect(() => {
    if (
      firstNameValid === "valid" &&
      lastNameValid === "valid" &&
      emailValid === "valid"
    ) {
      switch (studentTaking) {
        case "child":
          if (validateFormFields(parentName, "custName") === "valid") {
            setFormValid(true);
          } else {
            setFormValid(false);
          }
          break;
        default:
          setParentName("");
          setFormValid(true);
      }
    } else {
      setFormValid(false);
    }
  }, [firstNameValid, lastNameValid, emailValid, parentName, studentTaking]);

  const handleSelection = ({ target }) => {
    if (target.name === "lessonDuration") {
      setDuration(target.value);
      setPrice(priceMap[target.value]);
      setStartDate("");
      setDesc("");
      setTimeSlot("");
      if (scheduleNow === "yes") {
        setFinalAction("Schedule");
      } else {
        setFinalAction("Add to Cart");
      }
    } else if (target.name === "next1") {
      setBookStep(2);
    } else if (target.name === "next2") {
      setBookStep(3);
      document.dispatchEvent(lessonAddToCart);
      routeChange();
    } else if (target.name === "schdl") {
      setScheduleModal(true);
    } else if (target.name === "scheduleNow") {
      setScheduleNow(target.value);
      if (target.value === "yes" && !timeSlot) {
        setFinalAction("Schedule");
      } else {
        setFinalAction("Add to Cart");
      }
    } else if (target.name === "lessonCount") {
      setCount(target.value);
      var lessonPrice = priceMap[duration];
      setPrice(lessonPrice * target.value);
    } else if (target.value === "in-store") {
      setLessonType("in-store");
      setStartDate("");
      setDesc("");
      setTimeSlot("");
      if (scheduleNow === "yes") {
        setFinalAction("Schedule");
      } else {
        setFinalAction("Add to Cart");
      }
    } else if (target.value === "online") {
      setLessonType("online");
      setStartDate("");
      setDesc("");
      setTimeSlot("");
      if (scheduleNow === "yes") {
        setFinalAction("Schedule");
      } else {
        setFinalAction("Add to Cart");
      }
    } else if (target.name === "first-name") {
      setFirstName(target.value);
      setFirstNameValid(validateFormFields(target.value, "custName"));
    } else if (target.name === "last-name") {
      setLastName(target.value);
      setLastNameValid(validateFormFields(target.value, "custName"));
    } else if (target.name === "student-taking") {
      setStudentTaking(target.value);
    } else if (target.name === "cust-email") {
      setCustEmail(target.value);
      if (target.classList.contains("is-invalid")) {
        setEmailValid(validateFormFields(target.value, "custEmail"));
      }
    } else if (target.name === "parent-name") {
      setParentName(target.value);
    }
  };

  const handleInstValidation = (value) => {
    if (value) {
      setInstrument(value);
      setInstInvalid(false);
    } else {
      setInstrument("");
      setInstInvalid(true);
    }
  };

  const handleEventClick = (event) => {
    var jsonStr = JSON.stringify(event.event, null, 2);
    var jsonObj = JSON.parse(jsonStr);
    var day = moment(jsonObj.start).format("dddd, MMM Do, h:mm A");
    var time = moment(jsonObj.start).format("HH:mm");
    var startDate = moment(jsonObj.start).format("MM/DD/yyyy");
    var desc =
      "Lesson Every " +
      moment(jsonObj.start).format("dddd") +
      " between " +
      moment(jsonObj.start).format("h:mm A") +
      " - " +
      moment(jsonObj.end).format("h:mm A");
    setStartDate(startDate);
    setDesc(desc);
    setTimeSlot(day);
    setTime(time);
    setFinalAction("Add to Cart");
    setScheduleModal(false);
    setContactInstructor(props.instructor);
  };

  const finalTermsAndConditionsAcceptHandler = (e) => {
    e.preventDefault();
    setTermsModal(false);
    axios
      .post(
        `${searchProxyAPIUrl}/iquery`,
        '{"query": {"match": { "instructorId.keyword":"' +
          props.instructor.instructorId +
          '"} } }\n',
        {
          headers: {
            "x-api-key": searchProxyAPIKey,
          },
        }
      )
      .then((res) => {
        if (scheduleNow === "later") {
          document.dispatchEvent(lessonAddToCart);
          setBookStep(3);
          return routeChange();
        }
        const availableTimes =
          res?.data?.responses?.[0]?.hits?.hits?.[0]?._source?.availability;
        const formatStr = "YYYY-MM-DDTHH:mm";
        const lessons = [];
        const startTime = moment(startDate + " " + time);
        lessons.push(
          `${startTime.format(formatStr)};${moment(startTime)
            .add(30, "minutes")
            .format(formatStr)}`
        );
        if (duration === "60") {
          lessons.push(
            `${moment(startTime).add(30, "minutes").format(formatStr)};${moment(
              startTime
            )
              .add(60, "minutes")
              .format(formatStr)}`
          );
        }
        const selectedTimeIsAvailable = lessons.every((lessonTime) =>
          availableTimes.some((t) => t.startsWith(lessonTime))
        );
        if (selectedTimeIsAvailable) {
          document.dispatchEvent(lessonAddToCart);
          setBookStep(3);
          routeChange();
        } else {
          setTimeUnavailable(true);
        }
      })
      .catch(() => {
        document.dispatchEvent(lessonAddToCart);
        setBookStep(3);
        routeChange();
      });
  };

  const url_catalog_ref_id_30 =
    process.env.REACT_APP_ATG_HOST === "https://tl1-www.guitarcenter.com"
      ? "site51600000000176"
      : props.instructor.thirtyskuId;
  const url_catalog_ref_id_60 =
    process.env.REACT_APP_ATG_HOST === "https://tl1-www.guitarcenter.com"
      ? "site51600000000170"
      : props.instructor.sixtyskuId;
  let url_product_id =
    process.env.REACT_APP_ATG_HOST === "https://tl1-www.guitarcenter.com"
      ? "site51600000000000159"
      : props.instructor.sixtyproductId;
  const routeChange = () => {
    var student_details_params = `&first_name=${firstName}&last_name=${lastName}&cust_email=${custEmail}&parent_name=${parentName}&lesson_for=${studentTaking}`;
    let path =
      process.env.REACT_APP_ATG_HOST +
      "/ajax/redirectToCart.jsp?dcs_action=additemtocart&appointmentTime=" +
      time +
      "&lessonType=" +
      lessonType +
      "&instructorInternalID=" +
      props.instructor.instructorId +
      "&url_catalog_ref_id=" +
      url_catalog_ref_id_60 +
      "&url_product_id=" +
      url_product_id +
      "&url_quantity=1&&commerce_type=New&lesson_store_id=" +
      props.instructor.storeNumber +
      "&lesson_instrument=" +
      instrument.value +
      "&lesson_duration=" +
      duration +
      "&is_scheduled_item=true&schedule_start_date=" +
      startDate +
      "&scheduled_description=" +
      desc +
      student_details_params;
    if (duration == 30) {
      url_product_id =
        process.env.REACT_APP_ATG_HOST === "https://tl1-www.guitarcenter.com"
          ? "site51600000000000159"
          : props.instructor.thirtyproductId;
      path =
        process.env.REACT_APP_ATG_HOST +
        "/ajax/redirectToCart.jsp?dcs_action=additemtocart&appointmentTime=" +
        time +
        "&lessonType=" +
        lessonType +
        "&instructorInternalID=" +
        props.instructor.instructorId +
        "&url_catalog_ref_id=" +
        url_catalog_ref_id_30 +
        "&url_product_id=" +
        url_product_id +
        "&url_quantity=1&&commerce_type=New&lesson_store_id=" +
        props.instructor.storeNumber +
        "&lesson_instrument=" +
        instrument.value +
        "&lesson_duration=" +
        duration +
        "&is_scheduled_item=true&schedule_start_date=" +
        startDate +
        "&scheduled_description=" +
        desc +
        student_details_params;
    }
    window.location.href = path;
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#DDDDDD"
          : isFocused
          ? "#F5F5F5"
          : null,
        color: "black",
        fontWeight: 500,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#DDDDDD" : "#F5F5F5"),
        },
      };
    },
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
  };
  const hasImage =
    props.instructor.imagename &&
    props.instructor.imagename !==
      "https://rstatic.stores.musicarts.com/locations/1_36_person-default_1_.png";
  const hideInstructorName = !props.instructor.shortbio && !hasImage;
  const storeAddress = props.instructor.storeaddress2
    ? `${props.instructor.storeaddress1} ${props.instructor.storeaddress2}`
    : props.instructor.storeaddress1;
  const instructorStore = `${props.instructor.storecity} - ${storeAddress}, ${props.instructor.storecity}, ${props.instructor.storestate} ${props.instructor.storezip}`;
  const lessonLocation = lessonType === "in-store" ? instructorStore : "online";

  const handleScroll = React.useCallback(
    (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 20; // add a small tolerance for padding

      if (atBottom && !termsAccepted) {
        document.dispatchEvent(lessonAcceptTerms);
        setTermsAccepted(true);
      }
    },
    [setTermsAccepted, termsAccepted]
  );

  const finalBookNowModalActionButtonHandler = React.useCallback(
    (e) => {
      e.preventDefault();
      if (finalAction === "Add to Cart") {
        setTermsModal(true);
      } else {
        handleSelection({ target: { name: "schdl" } });
      }
    },
    [finalAction, handleSelection]
  );

  const handleKeyDownSelectionAction =
    (eventHandler, isDisabled = false) =>
    (ev) => {
      if (["Space", "Enter"].includes(ev.code) && !isDisabled) {
        eventHandler(ev);
      }
    };

  return (
    <>
      <div className="modal-header no-border-header text-center">
        {!hideInstructorName ? (
          <img
            className="book-now-avatar"
            src={props.instructor.imagename}
          ></img>
        ) : null}
        <button
          className="close mdl-close"
          type="button"
          onClick={() => props.modalFn(false)}
        >
          <span>×</span>
        </button>
        <h4 className="modal-title text-center contact-modal">
          Let’s get you scheduled
        </h4>
      </div>
      <div className="modal-body" style={{ height: "100%" }}>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Row className="ml-auto">
                <Label className="font-weight-bold">Location</Label>
              </Row>
              <Row className="dur-opt">
                <Col className="pad-r-0">
                  <FormGroup check inline>
                    <Label
                      className="dow-lbl"
                      style={{ fontWeight: "500", fontSize: "16px" }}
                    >
                      {lessonLocation}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <Row className="ml-auto">
              <Label className="font-weight-bold">Duration</Label>
            </Row>
            <Row className="dur-opt">
              <Col className="pad-r-0">
                <FormGroup check inline style={{ marginRight: 0 }}>
                  <Label check className="dow-lbl">
                    <Input
                      id="lessonDuration1"
                      name="lessonDuration"
                      type="checkbox"
                      value={"30"}
                      onChange={handleSelection}
                      checked={duration === "30"}
                    />
                    30 minutes <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              </Col>
              <Col className="pad-r-0">
                <FormGroup check inline style={{ marginRight: 0 }}>
                  <Label check className="dow-lbl">
                    <Input
                      id="lessonDuration2"
                      name="lessonDuration"
                      type="checkbox"
                      value={"60"}
                      onChange={handleSelection}
                      checked={duration === "60"}
                    />
                    60 minutes <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Collapse isOpen={bookStep < 2}>
          <Row>
            <Col>
              <Button
                className="btn-round next1-btn"
                color="default"
                href="!#"
                name="cancel1"
                onClick={(e) => {
                  e.preventDefault();
                  props.modalFn(false);
                }}
                disabled={bookStep > 1}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="btn-round next1-btn"
                style={{ float: "right" }}
                color="danger"
                href="!#"
                name="next1"
                onClick={(e) => {
                  e.preventDefault();
                  handleSelection(e);
                }}
                disabled={bookStep > 1 || instInvalid}
              >
                Next
              </Button>
            </Col>
          </Row>
        </Collapse>

        <Collapse isOpen={bookStep > 1}>
          <Row className="row-next2">
            <Col md="6" xs="12">
              <Row className="ml-auto">
                <Label className="font-weight-bold">
                  Billing Type{" "}
                  <a
                    href="!#"
                    onClick={(e) => e.preventDefault()}
                    id="know-more-subscription"
                    className="know-more-subs"
                  >
                    (?)
                  </a>
                </Label>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="know-more-subscription"
                >
                  All lessons are sold as four-packs. Your weekly lessons will
                  be billed on a monthly basis.
                </UncontrolledTooltip>
              </Row>
              <Row className="dur-opt">
                {/* <Col className="pad-r-0">
                                        <FormGroup check inline>
                                        <Label check className="dow-lbl">
                                            <Input
                                            id="lessonCount1"
                                            name="lessonCount"
                                            type="checkbox"
                                            value="1"
                                            onChange={handleSelection}
                                            checked={count <= 1}
                                            />
                                            1 Lesson <span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                    </Col>
                                    <Col className="pad-r-0">
                                        <FormGroup check inline>
                                        <Label check className="dow-lbl">
                                            <Input
                                            id="lessonCount2"
                                            name="lessonCount"
                                            type="checkbox"
                                            value="4"
                                            onChange={handleSelection}
                                            checked={count > 1}
                                            />
                                            Subscribe <span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                    </Col> */}
                <Col className="pad-r-0">
                  <FormGroup check inline>
                    <Label
                      className="dow-lbl subs-ind"
                      style={{ fontWeight: "500", fontSize: "16px" }}
                    >
                      Subscription
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md="6" xs="12">
              <Row className="ml-auto">
                <Label className="font-weight-bold">
                  Do you want to schedule your lesson today?
                </Label>
              </Row>
              <Row className="dur-opt">
                <Col className="pad-r-0">
                  <FormGroup check inline>
                    <Label check className="dow-lbl">
                      <Input
                        id="scheduleNow1"
                        name="scheduleNow"
                        type="checkbox"
                        value="yes"
                        onChange={handleSelection}
                        checked={scheduleNow === "yes"}
                      />
                      Yes <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs="12" md="12">
              <h4 className="booking-student-section">Student Information</h4>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Row className="ml-auto">
                  <Label className="font-weight-bold bnReqField">
                    First Name
                  </Label>
                </Row>
                <Input
                  className="input"
                  type="text"
                  name="first-name"
                  onBlur={(e) =>
                    setFirstNameValid(
                      validateFormFields(e.target.value, "custName")
                    )
                  }
                  onChange={handleSelection}
                  invalid={firstNameValid === "invalid"}
                />
                <FormFeedback>Please enter a first name</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Row className="ml-auto">
                  <Label className="font-weight-bold bnReqField">
                    Last Name
                  </Label>
                </Row>
                <Input
                  className="input"
                  type="text"
                  name="last-name"
                  onBlur={(e) =>
                    setLastNameValid(
                      validateFormFields(e.target.value, "custName")
                    )
                  }
                  onChange={handleSelection}
                  invalid={lastNameValid === "invalid"}
                />
                <FormFeedback>Please enter a last name</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="12" xs="12">
              <FormGroup>
                <Row className="ml-auto">
                  <Label className="font-weight-bold bnReqField">Email</Label>
                </Row>
                <Input
                  className="input"
                  type="text"
                  name="cust-email"
                  onBlur={(e) =>
                    setEmailValid(
                      validateFormFields(e.target.value, "custEmail")
                    )
                  }
                  onChange={handleSelection}
                  invalid={emailValid === "invalid"}
                />
                <FormFeedback>Please enter a valid email</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="12" xs="12">
              <Row className="ml-auto">
                <Label className="font-weight-bold">
                  Who will be taking lessons?
                </Label>
              </Row>
              <Row className="dur-opt">
                <Col className="pad-r-0">
                  <FormGroup check inline>
                    <Label check className="dow-lbl">
                      <Input
                        id="student-taking1"
                        name="student-taking"
                        type="checkbox"
                        value="myself"
                        onChange={handleSelection}
                        checked={studentTaking === "myself"}
                      />
                      Me <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </Col>
                <Col className="pad-r-0">
                  <FormGroup check inline>
                    <Label check className="dow-lbl">
                      <Input
                        id="student-taking3"
                        name="student-taking"
                        type="checkbox"
                        value="child"
                        onChange={handleSelection}
                        checked={studentTaking === "child"}
                      />
                      My child <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </Col>
                <Col className="pad-r-0">
                  <FormGroup check inline>
                    <Label check className="dow-lbl">
                      <Input
                        id="student-taking2"
                        name="student-taking"
                        type="checkbox"
                        value="other"
                        onChange={handleSelection}
                        checked={studentTaking === "other"}
                      />
                      Someone else <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </Col>

                {studentTaking === "child" ? (
                  <Col md="12" xs="12">
                    <FormGroup>
                      <Row className="ml-auto">
                        <Label className="font-weight-bold bnReqField">
                          Parent/Guardian Name
                        </Label>
                      </Row>
                      <Input
                        className="input"
                        type="text"
                        name="parent-name"
                        value={parentName}
                        onChange={handleSelection}
                      />
                      <FormFeedback>Please enter a name</FormFeedback>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6" xs="9" className="my-auto">
              <div className="desc-ct">
                <span style={{ fontSize: "16px" }}>
                  {`${duration}-minute ${count > 1 ? "weekly" : ""} ${
                    instrument.value
                  } ${count > 1 ? "lessons" : "lesson"}
                                        with ${
                                          hideInstructorName
                                            ? "Guitar Center Instructor"
                                            : props.instructor.name
                                        } `}
                </span>
                <a
                  href="!#"
                  onClick={(e) => {
                    e.preventDefault();
                    setScheduleModal(true);
                  }}
                  className="know-more-subs"
                >
                  {`${
                    timeSlot && scheduleNow === "yes"
                      ? (count > 1 ? "starting on " : "on ") + timeSlot
                      : ""
                  }`}
                </a>
              </div>
            </Col>
            <Col md="6" xs="3">
              <Row>
                <div className="sess-price">
                  <Label className="font-weight-bold">Price</Label>
                  <span>{`$${price}`}</span>
                </div>
              </Row>
            </Col>
          </Row>
          <hr />
          <Collapse isOpen={bookStep === 2}>
            <Row>
              <Col md="6" xs="4">
                <Button
                  className="btn-round next1-btn"
                  color="default"
                  href="!#"
                  name="cancel1"
                  onClick={(e) => {
                    e.preventDefault();
                    props.modalFn(false);
                  }}
                  disabled={bookStep > 2}
                >
                  Cancel
                </Button>
              </Col>
              <Col md="6" xs="8">
                <Button
                  className="btn-round next1-btn"
                  style={{ float: "right" }}
                  color="danger"
                  href="!#"
                  aria-label={finalAction}
                  onKeydown={handleKeyDownSelectionAction(
                    finalBookNowModalActionButtonHandler,
                    instInvalid || !formValid
                  )}
                  onClick={finalBookNowModalActionButtonHandler}
                  disabled={instInvalid || !formValid}
                >
                  {finalAction}
                </Button>
              </Col>
            </Row>
          </Collapse>
        </Collapse>
        <Modal
          isOpen={scheduleModal}
          toggle={() => setScheduleModal(false)}
          className="modal-schd-now"
        >
          <div className="modal-header no-border-header text-center">
            <button
              className="close mdl-close"
              type="button"
              onClick={() => setScheduleModal(false)}
            >
              <span>×</span>
            </button>
            {!hideInstructorName ? (
              <img
                className="book-now-avatar"
                src={props.instructor.imagename}
              ></img>
            ) : null}
            <h3 className="modal-title text-center contact-modal">
              Scheduling Lessons with{" "}
              {hideInstructorName
                ? "Guitar Center Instructor"
                : props.instructor.name}
            </h3>
            <p>Click on a red block below to book a date and time</p>
          </div>
          <div className="modal-body" id="schedule-modal-body">
            <div className="schedule-legend">
              <p>Available</p>
            </div>
            <div>
              <CustomCalendar
                view="timeGridWeek"
                handleEventClick={handleEventClick}
                instructor={contactInstructor}
                duration={duration}
                lessonType={lessonType}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={termsModal}
          labelledBy="termsModalHeader"
          toggle={() => {
            document.dispatchEvent(lessonClosedTerms);
            setTermsModal(false);
            setTermsAccepted(false);
          }}
          className="modal-terms"
        >
          <div className="modal-header no-border-header text-center">
            <button
              className="close mdl-close"
              type="button"
              onClick={() => {
                document.dispatchEvent(lessonClosedTerms);
                setTermsModal(false);
                setTermsAccepted(false);
              }}
            >
              <span>×</span>
            </button>
            <h3
              id="termsModalHeader"
              className="modal-title text-center contact-modal"
            >
              Lessons Terms & Conditions
            </h3>
          </div>
          <div className="modal-body">
            <p className="terms-help" id="termsHelper">
              Please scroll to the bottom of the Terms & Conditions to
              acknowledge
            </p>
            <div
              tabIndex={0}
              id="termDescription"
              aria-describedby="termsHelper"
              className="terms-modal"
              onScroll={handleScroll}
            >
              <p>
                <span>
                  <strong>LESSON SERVICES. </strong>
                </span>{" "}
                <span>
                  This Student Enrollment Agreement sets forth the terms and
                  conditions of your purchase of instructional services from
                  Guitar Center Stores, Inc. (“GC Lessons”).
                </span>
              </p>

              <p>
                <span>
                  <strong>TECHNOLOGY FEE.</strong>
                </span>{" "}
                <span>
                  There is a $30 non-refundable technology fee per student with
                  this purchase.
                </span>
              </p>

              <p>
                <span>
                  <strong>INDIVIDUAL LESSONS.</strong>
                </span>{" "}
                <span>
                  The recurring tuition fee provides students with individual
                  weekly lessons per month.
                </span>
              </p>

              <p>
                <span>
                  <strong>TUITION.</strong>
                </span>{" "}
                <span>
                  Tuition and technology fee are due at the time of purchase, in
                  advance, for the following four lessons. After purchasing your
                  first lessons package, your next subsequent lesson tuition
                  will be billed twenty-two (22) calendar days after the date of
                  your first lesson. Thereafter, lessons tuition is billed every
                  four weeks. For example: A student that takes their first
                  lesson on January 12
                  <sup>th</sup> will be billed their next lesson tuition 22 days
                  from January 12 (i.e., February 3) and every four-weeks
                  starting from February 3 moving forward. Tuition does not
                  include curriculum and materials costs. We may increase
                  monthly fees in future months upon 30 days’ written notice.
                </span>
              </p>

              <p>
                <span>
                  Tuition fees may be refunded in full prior to the delivery of
                  any instructional services if GC Lessons has received your
                  request to cancel at{" "}
                  <a
                    target="_blank"
                    href="https://www.guitarcenter.com/Services/Lessons/Account-Request"
                  >
                    https://www.guitarcenter.com/Services/Lessons/Account-Request
                  </a>{" "}
                  within 24 hours of your initial registration date.
                </span>
              </p>

              <p>
                <span>
                  <strong>PAYMENT AUTHORIZATION.</strong>
                </span>{" "}
                <span>
                  I hereby authorize GC Lessons, or any of its affiliate
                  companies, to effect payment for approved monthly fees and
                  charges for the duration of monthly lessons through credit
                  card automatic billing which will be billed per these terms
                  and conditions. This authorization is to remain in full effect
                  until GC Lessons has received your request to cancel at{" "}
                  <a
                    target="_blank"
                    href="https://www.guitarcenter.com/Services/Lessons/Account-Request"
                  >
                    https://www.guitarcenter.com/Services/Lessons/Account-Request
                  </a>{" "}
                  prior to the upcoming bill date of the subsequent month.
                </span>
              </p>

              <p>
                <span>
                  <strong>REGULAR ATTENDANCE</strong>
                </span>{" "}
                <span>
                  Regular attendance is critical to the success of lessons.
                  Please plan to attend all lessons and arrive on time and
                  prepared.
                </span>
              </p>

              <p>
                <span>
                  <strong>LESSONS RESCHEDULE POLICY. </strong>
                </span>{" "}
                <span>
                  To reschedule a lesson you must provide at least 24 hours
                  prior notice. We will reschedule your lesson subject to
                  instructor availability. If you do not show up for a lesson or
                  if you do not provide adequate notice you will not have the
                  opportunity to reschedule. To encourage attendance and the use
                  of lessons all unattended lessons will expire and be forfeited
                  ninety (90) days after purchase. There will be no refunds or
                  cash redemption available for expired lessons.
                </span>
              </p>

              <p>
                <span>
                  <strong>CANCELLATION OF ENROLLMENT. </strong>
                </span>
                <span>
                  You may cancel your enrollment at any time. To cancel your
                  enrollment and terminate your lessons you must enter your
                  request to cancel at{" "}
                  <a
                    target="_blank"
                    href="https://www.guitarcenter.com/Services/Lessons/Account-Request"
                  >
                    https://www.guitarcenter.com/Services/Lessons/Account-Request
                  </a>{" "}
                  prior to the upcoming bill date of the subsequent month.
                </span>
              </p>

              <p>
                <span>
                  Termination is effective on the date of notice. In the case of
                  enrollment cancellation, all lesson tuition fees paid are
                  non-refundable and any unpaid fees are immediately due. All
                  unattended lessons for cancelled enrollment accounts will
                  expire and be forfeited thirty (30) days from the date of the
                  notice of cancellation. There will be no refunds or cash
                  redemption available for expired unused lessons for canceled
                  enrollment accounts.
                </span>
              </p>

              <p>
                <span>
                  GC Lessons may, at its option, cancel your enrollment if you
                  (a) do not make timely payments; (b) you fail to show up
                  without notice for 2 consecutive lessons; (c) your conduct is
                  rude, improper, or harmful; or (d) you have not taken a lesson
                  or completed your registration for 90 days.
                </span>
              </p>

              <p>
                <span>
                  <strong>POLICIES FOR MINORS. </strong>
                </span>{" "}
                <span>
                  Communications between GC Lessons staff and with minor
                  students should only be on site, at the facility, within GC
                  Lessons systems, and directly related to instruction. Any
                  other communication should be between the adult
                  parent/guardian of any minor student and GC Lessons staff
                  through GC Lessons operated phones, GC systems, and proper
                  email addresses through GC Lessons. An adult parent or
                  guardian must accompany any student under 12 years of age upon
                  arrival and departure from every in-store lesson.
                  Additionally, there are security cameras placed in the GC
                  Lessons studios to capture the Lessons (video-only) to further
                  ensure the safety of both students and instructors. By
                  completing the registration, you consent to the use of
                  security cameras in the Lessons studios.
                </span>
              </p>

              <p>
                <span>
                  <strong>LESSONS DIGITAL PRACTICE SUPPORT. </strong>
                </span>
                <span>
                  Prior to using GC Lessons Digital Practice Support
                  (“Level-Up”), which facilitates audio and video recording, you
                  will be asked to provide consent for both audio and video
                  recording. If the student is a minor, the consent of the
                  parent or other legal guardian will be required.
                </span>
              </p>

              <p>
                <span>
                  The recordings capture the voice and video of the student and
                  instructor during a lesson and during practice time. The
                  recordings will be stored in GC Lessons Cloud service for five
                  days from the date of instruction, provided a student or legal
                  guardian has chosen the “Save” option at the end of each
                  lesson. The recordings are accessible to the student, legal
                  guardian, the instructor, and Guitar Center during these five
                  days.
                </span>
              </p>

              <p>
                <span>
                  Recordings are captured by GC Lessons to ensure the safety of
                  both students and instructors and improve the quality of the
                  student’s performance and are not used or shared for any other
                  purpose. By completing the registration, you consent to the
                  use of Level-Up as a student or legal guardian of a student.
                </span>
              </p>

              <p>
                <span>
                  <strong>LESSONS PERFORMANCE. </strong>
                </span>
                <span>
                  From time to time, GC Lessons may record, photograph or video
                  a student during recitals and other public performances to be
                  used on Guitar Center websites and any other medium. By
                  completing the registration, you consent to such use as a
                  student or legal guardian of a student.
                </span>
              </p>
            </div>
          </div>
          <div className="modal-footer no-border-footer">
            <div className="left-side p-3" />
            <div className="right-side p-3">
              <Button
                color="danger"
                href="!#"
                className="btn-wd"
                aria-label={"Accept Terms And Conditions Button"}
                disabled={!termsAccepted}
                onClick={finalTermsAndConditionsAcceptHandler}
                onKeydown={handleKeyDownSelectionAction(
                  finalTermsAndConditionsAcceptHandler,
                  !termsAccepted
                )}
              >
                Accept
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          size="sm"
          isOpen={timeUnavailable}
          className="time-unavailable-modal"
        >
          <div className="modal-body">
            <p>
              <strong>
                Your selected time slot is not available. Please select a
                different time for your lessons.
                <br />
                <br />
              </strong>
            </p>
            <Button
              color="primary"
              className="float-right"
              size="normal"
              onClick={() => setTimeUnavailable(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default BookNowModal;
