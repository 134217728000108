import React from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Card,
  CardBody,
  Modal,
  Container,
} from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import { DemoInstrumentList } from "../../../views/examples/DemoInstructorListing.js";

const lessonSelectInstrument = new Event("LESSON_SELECT_INSTRUMENT");

const StepZeroGeneral = ({ step, setStep, user, setUser }) => {
  const userInstrument =
    user && user.instrument && user.instrument.length ? user.instrument : [];
  const [instrumentSel, setInstrumentSel] = React.useState([userInstrument]);
  const handleUser = ({ target }) => {
    setUser({
      type: "INSTRUMENT",
      payload: { [target.name]: target.value },
    });
  };
  const handleContinue = (e) => {
    e.preventDefault();
    if (
      instrumentSel[0] &&
      instrumentSel[0][0] &&
      instrumentSel[0][0] === "Drums"
    ) {
      setInstrumentSel(["Drum"]);
      setUser({
        type: "INSTRUMENT",
        payload: { instrument: ["Drum"] },
      });
      console.log({ instrumentSel });
    }
    // if(!user.lessonFor){
    //     return;
    // }
    setStep(step + 1);
  };

  const handleSelection = (sel) => {
    setInstrumentSel([sel]);
    setUser({
      type: "INSTRUMENT",
      payload: { instrument: sel },
    });
    document.dispatchEvent(lessonSelectInstrument);
  };

  const instrumentList = [
    "Drums",
    "Keyboard",
    "Vocal",
    "Ukulele",
    "Violin",
    "Saxophone",
    "Mandolin",
    "Banjo",
    "Recording",
    "Electric Guitar",
    "Acoustic Guitar",
    "Electric Bass",
    "Viola",
    "Cello",
    "Double Bass",
    "Clarinet",
    "Flute",
    "Trombone",
    "Trumpet",
    "Horn",
    "Baritone",
    "DJ"
  ];  

  const handleSubmit = () => {
    document.getElementById("typeahead-next").click();
  };

  return (
    <Form action="" method="" onSubmit={handleSubmit}>
      <Row style={{ alignItems: "center" }}>
        <Col className="mb-auto-scr">
          <Typeahead
            id="basic-instrument-ta"
            labelKey="instrument"
            onChange={handleSelection}
            options={instrumentList}
            placeholder="Type to select your instrument"
            selected={instrumentSel[0]}
            clearButton
            className="basic-instrument-ta"
            minLength={1}
          />
          <Button
            id="typeahead-next"
            block
            type="submit"
            className="btn-round btn-danger"
            color="default"
            onClick={handleContinue}
            disabled={instrumentSel[0].length === 0}
          >
            Let's Go
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default StepZeroGeneral;
