import React, { useReducer, useState } from "react";
import {
  UserReducer,
  DefaultUser,
} from "../../gc-comps/reducer/UserReducer.js";
import { MinimizedFormSteps } from "./lessons-form/FormSteps.js";
import { Row, Col } from "reactstrap";

function QuestionSwitchCase(props) {
  var question = "";
  if (props.studentInd === "myself") {
    question = props.formContent.title;
  } else if (props.studentInd === "child") {
    question = props.formContent.titleChild;
  } else if (props.studentInd === "someone") {
    question = props.formContent.titleSomeOne;
  } else {
    question = props.formContent.title;
  }
  return (
    <h3
      className="color-dark modal-question"
      style={{ fontWeight: 900, maxWidth: "542px", margin: "30px auto 0" }}
    >
      {question}{" "}
    </h3>
  );
}

function HeroRegModal(props) {
  const [rLoading, setRLoading] = React.useState(false);
  let [step, setStep] = useState(0);
  const [user, setUser] = useReducer(UserReducer, DefaultUser);
  let steps = MinimizedFormSteps(
    step,
    setStep,
    user,
    setUser,
    setRLoading,
    props.modalFn
  );
  var instrumentSel = user.instrument.length > 0 ? user.instrument[0] : "";

  React.useEffect(() => {
    setUser({
      type: "INSTRUMENT",
      payload: { instrument: instrumentSel },
    });
  }, []);

  return (
    <div>
      <div className={`ldOverlay ${rLoading ? "dBlock" : "dNone"}`}>
        <div className="uil-reload-css reload-background reload-small mr-1 icLoader">
          <div />
        </div>
      </div>
      <button
        className="close"
        type="button"
        onClick={() => {
          props.modalFn(false);
        }}
        style={{ padding: "15px 15px 0 0" }}
      >
        <span>×</span>
      </button>

      <div
        className="modal-header no-border-header text-center"
        style={{ minHeight: "150px", maxHeight: "170px" }}
      >
        <Row className="justify-content-md-center">
          <Col xs="8" md="8" className="ml-auto mr-auto">
            <div className="progress-steps">
              {steps.map((item, i) => {
                return <div className={i >= step ? "next-step" : "complete-step"}></div>
              })}
            </div>
          </Col>
        </Row>

        <div>
          <QuestionSwitchCase
            studentInd={user.lessonFor}
            formContent={steps[step]}
          />
          {steps[`${step}`].optional ? (
            <p style={{ color: "#000", fontSize: "21px", fontWeight: "500" }}>
              Optional
            </p>
          ) :  null}
        </div>
      </div>
      <div className="modal-body reg-modal">
        <div className="reg-modal-content">{steps[step].content}</div>
      </div>
      <div className="modal-footer no-border-footer" id="reg-footer"></div>
    </div>
  );
}

export default HeroRegModal;
