import React from 'react';
import {
  Form, Input, Label
} from "reactstrap";
import ActionButtonsFrag from './frags/ActionButtonsFrag.js';

const lessonSelectLocationType = new Event('LESSON_SELECT_LOCATION_TYPE');

const StepFourteenGeneral = ({step, setStep, user, setUser, optional, setRLoading, optNodalFn}) => {
    const[btnText, setBtnText] = React.useState(optional && !user.lessonMode ? "Skip" : "Next");
    const handleUser = ({ target }) => {
        if(target.value.length > 0){
          setBtnText("Next");
        }else{
          setBtnText("Skip");
        }
        setUser({
          type: 'LESSON_MODE',
          payload: { [target.name]: target.value },
        });
        document.dispatchEvent(lessonSelectLocationType);
      }
    const handleContinue = (e) => {
        e.preventDefault();
        console.log("EEEEE", e.target);
        if(!optional && !user.lessonMode){
          return;
        }
        if(e.target.name.toLowerCase() === 'skip'){
          setStep(step+1);
        }else{
          setRLoading(true);
          setTimeout(() => {
            setRLoading(false);
            setStep(step+1);
          }, 300);
        }
    }
    const lessonModesArr = [
      {
        label : 'In-store',
        val : 'In-Store'
      },
      {
        label : 'Online (via Zoom)',
        val : 'Online'
      }
    ];
    return(
        <Form>
          <div className="reg-modal-form">
          {lessonModesArr.map((value, index) => {
              return (
                <div className="form-check-radio" key={`lessonMode-div-${index}`}>
                  <Label check key={`lessonMode-lbl-${index}`}>
                    <Input
                      id={`lessonMode-${index}`}
                      name="lessonMode"
                      type="radio"
                      value={value.val}
                      onChange={handleUser}
                      checked={user.lessonMode === value.val}
                    />
                    {value.label} <span className="form-check-sign" />
                  </Label>
                </div>
              )
            })}
          </div>  
          <ActionButtonsFrag setStep={setStep} step={step} handleContinue={handleContinue} continueBtnText={btnText} optNodalFn={optNodalFn}/>
        </Form>
    );
} 

export default StepFourteenGeneral;