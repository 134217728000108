import {
    CardBody,
    Collapse,
    Label,
    FormGroup,
    Input,
  } from "reactstrap";
import React from "react";

class InstrumentsPickerWrapper extends React.Component {
    constructor()
    {
        super();
        let instrumentLS = '';
        if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).instrument)
        {
            instrumentLS=JSON.parse(localStorage.getItem('userSelections')).instrument;
        }
        if(Array.isArray(instrumentLS))
        {
           this.state = {
               instrumentsCheckBoxes:instrumentLS
           }
        }
        else
        {
            this.state = {
                instrumentsCheckBoxes:[instrumentLS]
            }
        }
    }

    render() {
        let instruments = [];

        if (
            // checking for when component gets the aggregation results
            this.props.aggregations
            && this.props.aggregations.instruments
            && this.props.aggregations.instruments.buckets.length
        ) {
            instruments = this.props.aggregations.instruments.buckets.map(instruments => instruments.key);
        }

        return (
            <div>
                <Collapse isOpen={true}>
                    <CardBody>
                        {instruments.map((instrument, index) =>
                            <FormGroup check>
                              <Label check>
                                <Input value={instrument} type="checkbox" name="instrumentsCheckBoxes" id={instrument} className={instrument + ' instrumentsCheckBoxes'}
                                checked={this.state.instrumentsCheckBoxes.includes(instrument)}
                                onChange={ (event) => {
                                    const checkedBoxes = document.querySelectorAll('input[name=instrumentsCheckBoxes]:checked');
                                    var checkedBoxesArray = Array.from(checkedBoxes);
                                    var instrumentCheckedBoxes = [];
                                     var checkedBoxesAct = [];
                                    for(let val of checkedBoxesArray)
                                    {
                                        instrumentCheckedBoxes[instrumentCheckedBoxes.length]=val.value;
                                        var elkey = 'instructorMode.keyword';

                                        if(JSON.parse(localStorage.getItem('userSelections')).lessonMode && JSON.parse(localStorage.getItem('userSelections')).lessonMode.length>0)
                                        {
                                            var lmarray = Array.from(JSON.parse(localStorage.getItem('userSelections')).lessonMode);
                                            for(var ins of lmarray)
                                            {
                                                if(val.value==='Online')
                                                {
                                                    checkedBoxesAct[checkedBoxesAct.length] = ins + ' ' +val.value;
                                                }
                                                else
                                                {
                                                    checkedBoxesAct[checkedBoxesAct.length] = ins + ' ' +val.value +' Lesson';
                                                    checkedBoxesAct[checkedBoxesAct.length] = ins + ' ' +val.value;
                                                }
                                            }
                                            elkey = 'activityFull.keyword';
                                        }
                                        else
                                        {
                                            checkedBoxesAct[checkedBoxesAct.length] = val.value;
                                        }
                                    }
                                    var query = {
                                        query: {
                                            terms: { 'instruments.keyword': instrumentCheckedBoxes }
                                        }
                                    };
                                    if(checkedBoxesAct && checkedBoxesAct.length>0)
                                    {
                                        query = {
                                            query: {
                                                terms: { 'instruments.keyword': instrumentCheckedBoxes }
                                            }
                                        };
                                    }

                                    if(instrumentCheckedBoxes && instrumentCheckedBoxes.length===0)
                                    {
                                        query = {
                                            query: {
                                                "match_all": {}
                                            }
                                        };
                                    }
                                    this.setState({instrumentsCheckBoxes:instrumentCheckedBoxes});

                                    var ls = JSON.parse(localStorage.getItem('userSelections'));
                                    Object.keys(ls).forEach((key) => {
                                        if('instrument'==key)
                                        {
                                            ls[key]=instrumentCheckedBoxes;
                                        }
                                    });
                                    localStorage.setItem('userSelections', JSON.stringify(ls));
                                    if(document.getElementById('prxlt'))
                                    {
                                        document.getElementById('prxlt').click();
                                    }
                                    this.props.setQuery({
                                        query,
                                        value: instrumentCheckedBoxes
                                    })
                                }}/>
                                {instrument} <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                        )}
                        <FormGroup check>
                          <Label check>
                            <Input value="prx" type="checkbox" name="prxin" id="prxin" style={{display:'none'}}
                            onChange={ (event) => {
                                var query={match_all: {}};
                                if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).instrument
                                    && JSON.parse(localStorage.getItem('userSelections')).instrument.length>0)
                                {
                                    if(Array.isArray(JSON.parse(localStorage.getItem('userSelections')).instrument))
                                    {
                                        if(!JSON.parse(localStorage.getItem('userSelections')).instrument.includes('Not sure'))
                                        {
                                                query = {
                                                    terms: {
                                                        "instruments.keyword": JSON.parse(localStorage.getItem('userSelections')).instrument
                                                    }
                                               }
                                        }
                                   }
                                   else
                                   {
                                        if(!JSON.parse(localStorage.getItem('userSelections')).instrument.includes('Not sure'))
                                        {
                                            query = {
                                                    term: {
                                                        "instruments.keyword": JSON.parse(localStorage.getItem('userSelections')).instrument
                                                    }
                                               }
                                        }
                                   }
                               }
                                this.props.setQuery({
                                    query
                                })
                            }}/>
                          </Label>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </div>
        )
    }
}

export default InstrumentsPickerWrapper;