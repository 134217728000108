import React from "react";
import {
    Button,
    Container,
    Row,
    Col,
  } from "reactstrap";
import HeroTop from '../sections/HeroTop';

function LessonsLanding(props){

    localStorage.removeItem('userSelections');

    return(
        <>
            <HeroTop />
            {/* <div className="info-hover">
                <div className="dp-info-hover" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("section-get-started").scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                    });
                }}>
                    <h6>Know More</h6>
                    <i className="nc-icon nc-minimal-down"></i>
                </div>
            </div> */}
            {/* <div className="wrapper">
                <div className="section text-center landing-section" id="section-get-started">
                    <Container>
                        <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="title">It All Starts Here</h2>
                        </Col>
                        </Row>
                        <Row>
                        <Col md="4">
                            <div className="info">
                            <div className="icon icon-danger">
                                <h5 className="learn-step">1</h5>
                            </div>
                            <div className="description">
                                <h4 className="info-title">Pick Your Plan</h4>
                                <p className="description">
                                Learn guitar, bass, drums, piano, DJing and more with 30-minute or 60-minute music lessons, online or in select stores.
                                </p>
                                <Button
                                className="btn-link"
                                color="danger"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                >
                                See more
                                </Button>
                            </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info">
                            <div className="icon icon-danger">
                                <h5 className="learn-step">2</h5>
                            </div>
                            <div className="description">
                                <h4 className="info-title">Get Connected</h4>
                                <p>
                                We'll contact you to set up your schedule and match you with an expert instructor to start your lessons.
                                </p>
                                <Button
                                className="btn-link"
                                color="danger"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                >
                                See more
                                </Button>
                            </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="info">
                            <div className="icon icon-danger">
                                <h5 className="learn-step">3</h5>
                            </div>
                            <div className="description">
                                <h4 className="info-title">Start Learning</h4>
                                <p>
                                Get live feedback as you work one-on-one with your instructor and learn practice tips to keep progressing.
                                </p>
                                <Button
                                className="btn-link"
                                color="danger"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                >
                                See more
                                </Button>
                            </div>
                            </div>
                        </Col>
                        </Row>
                    </Container>
                </div>
            </div> */}
        </>
    );
}


export default LessonsLanding;