import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
import "./assets/css/custom-kit.css";
import "./assets/demo/react-demo.css";
// pages
import { getQueryStringVal } from './gc-comps/utility/CommonHandlers.js';
import InstructorListing from "./gc-views/layout/InstrcutorListing";
import InstructorProfile from "./gc-views/layout/InstructorProfile";
import LessonsLanding from './gc-views/layout/LessonsLanding';
import SampleCartLanding from "./gc-views/sections/SampleCartLanding.js";
import Index from "./views/Index.js";
import NucleoIcons from "./views/NucleoIcons.js";
import Presentation from "./views/Presentation.js";
import Sections from "./views/Sections.js";
import AboutUs from "./views/examples/AboutUs.js";
import AddProduct from "./views/examples/AddProduct.js";
import BlogPost from "./views/examples/BlogPost.js";
import BlogPosts from "./views/examples/BlogPosts.js";
import ContactUs from "./views/examples/ContactUs.js";
import Discover from "./views/examples/Discover.js";
import Ecommerce from "./views/examples/Ecommerce.js";
import Error404 from "./views/examples/Error404.js";
import Error422 from "./views/examples/Error422.js";
import Error500 from "./views/examples/Error500.js";
import LandingPage from "./views/examples/LandingPage.js";
import LoginPage from "./views/examples/LoginPage.js";
import ProductPage from "./views/examples/ProductPage.js";
import ProfilePage from "./views/examples/ProfilePage.js";
import RegisterPage from "./views/examples/RegisterPage.js";
import SearchWithSidebar from "./views/examples/SearchWithSidebar.js";
import Settings from "./views/examples/Settings.js";
import TwitterRedesign from "./views/examples/TwitterRedesign.js";
import { datadogRum } from "@datadog/browser-rum";

function App() {
  if (process.env.REACT_APP_DATADOG_ENVIRONMENT === "prod") {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 30,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
  }
  var source = getQueryStringVal('src');
    if(source === '' || source === null){
        source= 'gc';
    }
    localStorage.setItem('siteSource', source);

    // prevent salesforce buttons from reloading page
    const salesForceBtns = document.querySelectorAll('.interstitial-support-menu_buttons a');
    salesForceBtns.forEach(function(btn){
      btn.addEventListener("click", function(e){
        e.preventDefault();
      });
    });  
   
  return (
    <div className="App">
      
        <Switch>
          <Route path="/index" render={(props) => <Index {...props} />} />
          <Route path="/inst-listing" render={(props) => <InstructorListing {...props} />} />
          <Route path="/instructor-profile" render={(props) => <InstructorProfile {...props} />} />
          <Route path="/sample-cart-lessons" render={(props) => <SampleCartLanding {...props} />} />
          <Route
            path="/nucleo-icons"
            render={(props) => <NucleoIcons {...props} />}
          />
          <Route path="/sections" render={(props) => <Sections {...props} />} />
          <Route
            path="/presentation"
            render={(props) => <Presentation {...props} />}
          />
          <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
          <Route
            path="/add-product"
            render={(props) => <AddProduct {...props} />}
          />
          <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
          <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
          <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
          <Route path="/discover" render={(props) => <Discover {...props} />} />
          <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} />
          <Route path="/error-404" render={(props) => <Error404 {...props} />} />
          <Route path="/error-422" render={(props) => <Error422 {...props} />} />
          <Route path="/error-500" render={(props) => <Error500 {...props} />} />
          <Route
            path="/landing-page"
            render={(props) => <LandingPage {...props} />}
          />
          <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
          <Route
            path="/product-page"
            render={(props) => <ProductPage {...props} />}
          />
          <Route
            path="/profile-page"
            render={(props) => <ProfilePage {...props} />}
          />
          <Route
            path="/register-page"
            render={(props) => <RegisterPage {...props} />}
          />
          <Route
            path="/search-with-sidebar"
            render={(props) => <SearchWithSidebar {...props} />}
          />
          <Route path="/settings" render={(props) => <Settings {...props} />} />
          <Route
            path="/twitter-redesign"
            render={(props) => <TwitterRedesign {...props} />}
          />
          <Route path="/" render={(props) => <LessonsLanding {...props} />} />
          <Redirect to="/presentation" />
      </Switch>

    </div>
  );
}

export default App;
