import React from 'react';
import axios from 'axios';
import moment from 'moment';

const emptyStoreHours = '1::,2::,3::,4::,5::,6::,7::';
export const getToggledElements = (currentArray, targetValue) => {
    var resultArray = [];
    if(currentArray && currentArray.length > 0){
        resultArray = currentArray;
        var entInd = resultArray.indexOf(targetValue);
        if(entInd > -1){
            resultArray.splice(entInd, 1);
        }else{
            resultArray.push(targetValue);
        }
    }else{
        resultArray.push(targetValue);
    }
    return resultArray;
}

const getQuery = () => {
    if (typeof window !== 'undefined') {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams();
};
  
export const getQueryStringVal = (key) => {
    return getQuery().get(key);
};

export const getStores = (zip, distance) => {
    //const liveApiYextUrl = `https://liveapi.yext.com/v2/accounts/me/locations/geosearch?api_key=79ddf705a35f1ffb6fd166a76bf121ac&v=20190506&location=${zip}&radius=${distance}&&filters=[{'closed':'false'}]`;
    const liveApiYextUrl = `https://rqw9jvf3hj.execute-api.us-west-2.amazonaws.com/prod/v2/accounts/me/locations/geosearch?api_key=79ddf705a35f1ffb6fEd166a76bf121ac&v=20190506&location=${zip}&radius=${distance}`;
    return axios.get(liveApiYextUrl).then(function(res) {
        if(res.data) {
            var response = res.data.response;
            var filteredStores = response.locations.filter(store => !store.id.includes('_') && store.hours !== emptyStoreHours);
            var filteredStoreIds = filteredStores.map(vStore => {return vStore.id});
            var filteredDistances = response.locationDistances.filter(store => filteredStoreIds.includes(store.id));
            if(filteredStores.length > 3 && filteredDistances.length > 3){
                filteredStores = filteredStores.slice(0, 3);
                filteredDistances = filteredDistances.slice(0, 3);
            }
            return {
                storesList: filteredStores, selectedStore: filteredStores[0], locationDistances: filteredDistances
            };
        }
    })
};

export function formatStoreInfo(selectedStore) {    
    if (selectedStore && selectedStore.id) {
        let phoneNum = selectedStore.phone;
        const cleaned = ('' + phoneNum).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        var displayZip = selectedStore.zip;
        if(displayZip.includes('-')){
            displayZip = displayZip.split('-')[0];
        }
    if (match) {
        phoneNum = '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
        return (
            <div>
                <p>{selectedStore.address}</p>
                <p>{selectedStore.city + ', ' + selectedStore.state + ' ' + displayZip}</p>
                <p>{phoneNum}</p>
            </div>
        )
    } else {
        return;
    }
}

// formatting store hours for display on store selector and confirmation page
export function formatStoreHours(selectedStore) {
    if (selectedStore && selectedStore.id) {
        const hoursString = selectedStore.hours;
        let hoursArray = hoursString ? hoursString.split(','): [];

        hoursArray = hoursArray.map((item, i) => {
            const day = item.slice(2);
            let open = day.slice(0,5);
            open = moment(open, "HH:mm").format("h:mm A");
            let close = day.slice(6);
            close = moment(close, "HH:mm").format("h:mm A");

            const dayObj = {
                open: open, close: close
            };
            return dayObj;
        });

        const sundayHours = `${hoursArray[0].open} - ${hoursArray[0].close}`;
        // todo: check that all weekday hours are the same, if, return in a different format
        const weekdayHours = `${hoursArray[1].open} - ${hoursArray[1].close}`;
        const saturdayHours = `${hoursArray[6].open} - ${hoursArray[6].close}`;

        return (
            <div>
                <p className="store-info-bold">Store Hours</p>
                <p>SUN: {sundayHours ? sundayHours : ''}</p>
                <p>MON-FRI: {weekdayHours ? weekdayHours : ''}</p>
                <p>SAT: {saturdayHours ? saturdayHours : ''}</p>
            </div>
        )
    } else {
        return;
    }
}

export function validateFormFields(value, type){
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var phoneNoRex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var result = 'invalid';
    switch(type){
      case "custName":
        if(value && value.length > 0){
            result = "valid";
        }
        break;
      case "custEmail":
        if(emailRex.test(value)){
            result = "valid";
        }
        break;
      case "custPhone":
        if(phoneNoRex.test(value)){
            result = "valid";
        }
        break;
      case "custMsg":
        if(value && value.length > 0){
            result = "valid";
        }
        break;
      default:
    }
    return result;
}

export function getQueryForDaysOfWeek(selectedDays,avlInstArr,instArr){
    var reqData = {
        "query":{
           "bool":{
              "must":[
                 {
                    "bool":{
                       "must":[
                          {
                             "match_all":{
                                
                             }
                          },
                          {
                             "match_all":{
                                
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "bool":{
                                         "must":[
                                            {
                                               "script":{
                                                  "script":{
                                                     "source":"def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);if(params.min.contains(start.get(7))){return true;}}return false;}",
                                                     "params":{
                                                        "min":selectedDays
                                                     }
                                                  }
                                               }
                                            }
                                         ]
                                      }
                                   }
                                ]
                             }
                          },
                          {
                             "terms":{
                                "activityFull.keyword":avlInstArr
                             }
                          },
                          {
                             "terms":{
                                "instruments.keyword":instArr
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "exists":{
                                         "field":"availability.keyword"
                                      }
                                   }
                                ]
                             }
                          }
                       ]
                    }
                 }
              ]
           }
        },
        "size":21,
        "_source":{
           "includes":[
              "*"
           ],
           "excludes":[
              
           ]
        },
        "from":0,
        "sort":[
           {
              "_geo_distance":{
                 "storelatlong":{
                    "lat":"34.2024698",
                    "lon":"-118.8741429"
                 },
                 "order":"asc",
                 "unit":"mi",
                 "mode":"min",
                 "distance_type":"arc",
                 "ignore_unmapped":true
              }
           }
        ]
     };

     return reqData;
}

export function getQueryForTimeOfDay(selectedDays,avlInstArr,instArr,timeArr){
    var minMax = getMinMaxForTimeRange(timeArr);
    var reqData = {
        "query":{
           "bool":{
              "must":[
                 {
                    "bool":{
                       "must":[
                          {
                             "bool":{
                                "must":[
                                   {
                                      "bool":{
                                         "must":[
                                            {
                                               "script":{
                                                  "script":{
                                                     "source":"def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);def en = sf.parse(avail[elem].splitOnToken(';')[1].splitOnToken('#')[0]);def end = sf.getCalendar();end.setTime(en);if(start.get(11) >= params.min && end.get(11)<= params.max){if(end.get(11)<params.max || (end.get(11)==params.max && end.get(12)==0)){if(params.dayOfWeekCheckedBoxesI.length==0 || params.dayOfWeekCheckedBoxesI.contains(start.get(7))){return true;}}}}return false;}",
                                                     "params":{
                                                        "min":minMax[0],
                                                        "max":minMax[1],
                                                        "dayOfWeekCheckedBoxesI":selectedDays
                                                     }
                                                  }
                                               }
                                            }
                                         ]
                                      }
                                   }
                                ]
                             }
                          },
                          {
                             "match_all":{
                                
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "bool":{
                                         "must":[
                                            {
                                               "script":{
                                                  "script":{
                                                     "source":"def sf = new SimpleDateFormat('yyyy-MM-dd\\'T\\'HH:mm');if(doc['availability.keyword'].size()>0){def avail = doc['availability.keyword'];for(def elem=0; elem<avail.length; elem++){def st = sf.parse(avail[elem].splitOnToken(';')[0]);def start = sf.getCalendar();start.setTime(st);if(params.min.contains(start.get(7))){return true;}}return false;}",
                                                     "params":{
                                                        "min":selectedDays
                                                     }
                                                  }
                                               }
                                            }
                                         ]
                                      }
                                   }
                                ]
                             }
                          },
                          {
                             "terms":{
                                "activityFull.keyword": avlInstArr
                             }
                          },
                          {
                             "terms":{
                                "instruments.keyword": instArr
                             }
                          },
                          {
                             "bool":{
                                "must":[
                                   {
                                      "exists":{
                                         "field":"availability.keyword"
                                      }
                                   }
                                ]
                             }
                          }
                       ]
                    }
                 }
              ]
           }
        },
        "size":21,
        "_source":{
           "includes":[
              "*"
           ],
           "excludes":[
              
           ]
        },
        "from":0,
        "sort":[
           {
              "_geo_distance":{
                 "storelatlong":{
                    "lat":"34.2024698",
                    "lon":"-118.8741429"
                 },
                 "order":"asc",
                 "unit":"mi",
                 "mode":"min",
                 "distance_type":"arc",
                 "ignore_unmapped":true
              }
           }
        ]
     };

     return reqData;
}

function getMinMaxForTimeRange(tod){
            var min = 23;
            var max = 0;
            if(tod.includes('morning') || tod.includes('1'))
            {
                if(5<min)
                {
                    min=5;
                }
                if(12>max)
                {
                    max=12;
                }
            }
            if(tod.includes('afternoon') || tod.includes('2'))
            {
                if(13<min)
                {
                    min=13;
                }
                if(16>max)
                {
                    max=16;
                }
            }
            if(tod.includes('evening') || tod.includes('3'))
            {
                if(17<min)
                {
                    min=17;
                }
                if(23>max)
                {
                    max=23;
                }
            }
            if(tod.length===0 || (tod.includes('on') && tod.length === 1))
            {
                min=0;
                max=23;
            }

            return [min, max];
}