export const DefaultUser = {
    instrument: '',
    style: '',
    experience: '',
    lessonFor: '',
    readsMusic: '',
    ageRange: '',
    musicStyles: [],
    hasInstrument: '',
    continuity: '',
    frequency: '',
    duration: '',
    daysOfWeek: '',
    timeOfDay: '',
    startDate: '',
    readiness: '',
    lessonMode: 'In-Store',
    zipCode: '',

    aboutYou: '',
    goals: '',
    notes: '',
    studentInd: '',
    fixedStartDate: ''

  }


  export const UserReducer = (user, { type, payload }) => {
    switch (type) {
    case 'INSTRUMENT':
        return {
          ...user,
          ...payload,
        }
      case 'LESSON_FOR':
        return {
          ...user,
          ...payload,
        }
      case 'LESSON_EXP':
        return {
          ...user,
          ...payload,
        }
        case 'LESSON_READ_MUSIC':
        return {
          ...user,
          ...payload,
        }
        case 'LESSON_AGE_RANGE':
        return {
          ...user,
          ...payload,
        }
        case 'LESSON_MUSIC_STYLE':
        return {
          ...user,
          ...payload,
        }
        case 'LESSON_HAS_INSTR':
        return {
          ...user,
          ...payload,
        }
        case 'LESSON_CONTINUITY':
        case 'LESSON_READINESS':
        case 'LESSON_MODE':
        return {
          ...user,
          ...payload,
        }
        case 'LESSON_FREQUENCY':
        case 'LESSON_DURATION':
        case 'LESSON_DAYS_OF_WEEK':
        case 'LESSON_TIME':
        return {
          ...user,
          ...payload,
        }
        case 'LESSON_START':
            return {
                ...user,
                ...payload,
            }
        case 'LESSON_ABOUT_YOU':
        case 'LESSON_GOALS':
        case 'LESSON_NOTES':
            return {
                ...user,
                ...payload,
            }
        case 'LESSON_ZIP_CODE':
            return {
                ...user,
                ...payload,
            }
      default:
        return user
    }
  }