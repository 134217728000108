import React from 'react';

const ECOM487EmailUrl = `https://jrf6phar18.execute-api.us-west-2.amazonaws.com/default/sg` // using the API Gateway called "sam-emails-trigger (jrf6phar18)" in ECOM-487 account
const ECOM487EmailAPIKey = "8M4ogVDXVKHSc66KCZbX9NxeLsQRPcS6zkdSLHB4" // using the API Gateway API Key from the API Gateway "sam-emails-trigger (jrf6phar18)" in ECOM-487 account
const ECOM487SearchProxyAPIUrl = "https://45fknjpe5k.execute-api.us-west-2.amazonaws.com/default/search-proxy-api" // using the API Gateway called "ma-lpp-instructors (l8v6yk3195)" in ECOM-487 account
const ECOM487SearchProxyAPIKey = "uH45tVwVrj8AlfLPoil1f45JTijLEVnJ1oHFyeTb" // using the API Gateway API Key from the API Gateway "ma-lpp-instructors (l8v6yk3195)" in ECOM-487 account

export const emailAPIUrl = process.env.REACT_APP_SAM_BACKEND_URL && process.env.REACT_APP_SAM_BACKEND_URL !== "null" ? `${process.env.REACT_APP_SAM_BACKEND_URL}/email` : ECOM487EmailUrl,
  emailAPIKey = process.env.REACT_APP_SAM_BACKEND_API_KEY && process.env.REACT_APP_SAM_BACKEND_API_KEY !== "null" ? process.env.REACT_APP_SAM_BACKEND_API_KEY : ECOM487EmailAPIKey,
  searchProxyAPIUrl = process.env.REACT_APP_SAM_BACKEND_URL && process.env.REACT_APP_SAM_BACKEND_URL !== "null" ? `${process.env.REACT_APP_SAM_BACKEND_URL}/search-proxy-api` : ECOM487SearchProxyAPIUrl,
  searchProxyAPIKey = process.env.REACT_APP_SAM_BACKEND_API_KEY && process.env.REACT_APP_SAM_BACKEND_API_KEY !== "null" ? process.env.REACT_APP_SAM_BACKEND_API_KEY : ECOM487SearchProxyAPIKey;

export default {
    contactConsent : <span id="contactConsent" className="contactConsent">By submitting this form, I am providing consent to be contacted by Guitar Center. View our <a href="https://www.guitarcenter.com/pages/privacy-rights-policy" id="priv-policy-link" className="priv-policy-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</span>
}