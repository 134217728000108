import React from 'react';
import {
    Form, FormGroup, Input, Button, Label, Row, Col, Card, Modal, FormFeedback, UncontrolledTooltip
} from "reactstrap";
import axios from 'axios';
import {getStores, formatStoreInfo, formatStoreHours} from '../../gc-comps/utility/CommonHandlers.js';
import config, { searchProxyAPIUrl, searchProxyAPIKey, emailAPIKey, emailAPIUrl } from '../../config.js';
export default function GeneralContact(props) {

    var site = localStorage.getItem('siteSource');
    var zipRex = /^\d{5}(-\d{4})?$/;
    var uZip = '';
    if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).zipCode)
      {
        uZip=JSON.parse(localStorage.getItem('userSelections')).zipCode;
      }

    const[ctModal, setCtModal] = React.useState(false);
    const[sLoading, setSLoading] = React.useState(false);
    const[ackMsg, setAckMsg] = React.useState('');
    const[formValid, setFormValid] = React.useState(false);
    const[storeFormValid, setStoreFormValid] = React.useState(false);
    const[closeForm, setCloseForm] = React.useState(false);

    const[radiusInValid, setRadiusInValid] = React.useState('valid');
    const[zipInValid, setZipInValid] = React.useState(zipRex.test(uZip) ? 'valid' : '');
    const[nameInValid, setNameInValid] = React.useState('');
    const[emailInValid, setEmailInValid] = React.useState('');
    const[msgInValid, setMsgInValid] = React.useState('');
    const[phoneInvalid, setPhoneInvalid] = React.useState('');

    const[radius, setRadius] = React.useState(25);
    const[zipCode, setZipCode] = React.useState(uZip);

    const[storeResultSection, setStoreResultSection] = React.useState(null);

    const[uName, setUName] = React.useState('');
    const[storeId, setStoreId] = React.useState('');
    const[interests, setInterests] = React.useState('');
    const[email, setEmail] = React.useState('');
    const[phone, setPhone] = React.useState('');
    const[message, setMessage] = React.useState('');
    const[smsConsent, setSmsConsent] = React.useState(true);

    React.useEffect(() => {
      if(nameInValid === 'valid' && (emailInValid === 'valid' || phoneInvalid === 'valid')){
        setFormValid(true);
      }else{
        setFormValid(false);
      }
    }, [nameInValid, emailInValid, phoneInvalid]);

    React.useEffect(() => {
      if(radiusInValid === 'valid' && zipInValid === 'valid'){
        setStoreFormValid(true);
      }else{
        setStoreFormValid(false);
      }
    }, [radiusInValid, zipInValid]);

    const ackSuccess = "Thank you for your interest. We'll be in touch with you shortly.";
    const ackFailure = "Uh oh! Something wen't wrong. Please try again.";

    const searchStores = async(e) => {
        e.preventDefault();
        var result = await getStores(zipCode, radius);
        if(result && result.storesList.length > 0 && result.selectedStore){
          setStoreId(result.selectedStore.id);
          var resultSection = result.storesList.map((store, i) => {
            const index = result.locationDistances.findIndex(item => item.id === store.id);
            const distance = index > -1 ? (result.locationDistances[index].distanceMiles).toFixed(2) : null;
            const labelMarkup = distance ? `${store.city} - ${distance}mi` : store.city;
                if (i < 3) {
                    return (
                        <Col md="4" xs="12" key={`store-${i}`} className="store-option">
                            <Label check>
                              <Input
                                id={`storeIdSel_${store.id}`}
                                name="storeIdSel"
                                type="radio"
                                value={store.id} 
                                onChange={(e) => setStoreId(e.target.value)}
                                defaultChecked={i === 0}
                              />
                              <label className="store-head-bold">{`${i+1}. ${labelMarkup}`}</label> <span className="form-check-sign" />
                            </Label>
                            
                            <div className="store-info_details">
                                {formatStoreInfo(store)}
                                <div className="store-hours">
                                {formatStoreHours(store)}
                            </div>
                            </div>
                        </Col>
                    );
                }
                
            });
            setStoreResultSection(resultSection);
        }else{
          var noStoresSection = <span className="no-stores-msg">No stores near you within {radius} miles.<br/>Please procced with the details.</span>
          setStoreId(2000);
          setStoreResultSection(noStoresSection);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSLoading(true);
        var reqData = {
          id: new Date().getTime(),
          user: uName,
          interests : interests,
          email: email,
          phone: phone,
          message: message,
          smsConsent: smsConsent,
          status: 'submitted',
          brand: site,
          type : 'generalContact',
          store : storeId,
          zipCode : zipCode,
          radius : radius
        }
        let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "x-api-key": searchProxyAPIKey
          }
        };
  
        axios.post(`${searchProxyAPIUrl}/contact`, reqData, axiosConfig)
        .then(res => {
          clearFormFields();
          setSLoading(false);
          setAckMsg('ackSuccess');
          setCloseForm(true);
          setTimeout(() => {setCtModal(false); clearFormFields()}, 2000);
          let axiosConfigEmail = {
              headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  "x-api-key": emailAPIKey
              }
            };
            var reqDataEmail = {
                    id: JSON.parse(res.data)._id
            }
            axios.post(emailAPIUrl, reqDataEmail, axiosConfigEmail)
                  .then(res => {
                  });
        })
        .catch(err => {
            console.error(err);
            setSLoading(false);
            setAckMsg('ackFailure');
          }
        );
  }

  const validateFileds = (e) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var phoneNoRex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var field = e.target.name;
    var value = e.target.value;
    switch(field){
      case "custName":
        if(value && value.length > 0){
          setNameInValid("valid");
        }else{
          setNameInValid("invalid");
        }
        break;
      case "custEmail":
        if(emailRex.test(value)){
          setEmailInValid("valid");
          setPhoneInvalid('');
        }else{
          if(phoneNoRex.test(phone)){
            setEmailInValid("");
          }else{
            setEmailInValid("invalid");
            setPhoneInvalid("invalid");
          }
        }
        break;
      case "custPhone":
        if(phoneNoRex.test(value)){
          setPhoneInvalid("valid");
          setEmailInValid('');
        }else{
          if(emailRex.test(email)){
            setPhoneInvalid("");
          }else{
            setPhoneInvalid("invalid");
            setEmailInValid("invalid");
          }
        }
        break;
      case "custMsg":
        if(value && value.length > 0){
          setMsgInValid("valid");
        }else{
          setMsgInValid("invalid");
        }
        break;
      case "custZip":
        if(zipRex.test(value)){
          setZipInValid("valid");
        }else{
          setZipInValid("invalid");
        }
        break;
      case "custRadius":
        if(value && value >= 1 && value <=100){
          setRadiusInValid("valid");
        }else{
          setRadiusInValid("invalid");
        }
        break;
      default:
    }

  }

  const handleChange = (event) => {
    if (event.target.classList.contains('is-invalid')) {
      validateFileds(event);
    }
  }

      const clearFormFields = () => {
        setUName('');
        setEmail('');
        setPhone('');
        setInterests('');
        setMessage('');
        setSmsConsent(false);
        setRadius(25);
        setRadiusInValid('valid');
        setPhoneInvalid('');
        setEmailInValid('');
        setZipCode(uZip);
        setStoreId('');
        setSLoading(false);
        setCloseForm(false);
        setStoreResultSection(null);
        setAckMsg('');
      }

      const contactBtnClick = (e) => {
        if (window.embedded_svc) {
          const helpBtn = document.querySelector('button.helpButtonEnabled');
          helpBtn.click();
        } else {
          setCtModal(true);
        }
      }

    return(
        <>
                <Row className="mt-10">
                    <Col className="mx-auto my-auto gen-cnt-sec" md="auto" xs="auto">
                        <span className="help-sec">Still not sure, or want more info first?</span>
                    </Col>
                    <Col className="mx-auto gen-cnt-sec" md="auto" xs="auto">
                        <Button type="submit" className="btn btn-primary pull-right" color="danger" onClick={(e) => contactBtnClick(e)}>
                            Contact Us
                        </Button>
                    </Col>
                </Row>
            <Modal
                        isOpen={ctModal}
                        toggle={() => {setCtModal(false); clearFormFields();}}
                        className="modal-schd-now"
                        >
                          <div className={`ldOverlay ${sLoading ? "dBlock" : "dNone"}`}>
                            <div className="uil-reload-css reload-background reload-small mr-1 icLoader">
                                <div />
                              </div>
                          </div>
                            <div className="modal-header no-border-header text-center">
                  <button
                    className="close mdl-close"
                    type="button"
                    onClick={() => {setCtModal(false); clearFormFields();}}
                  >
                    <span>×</span>
                  </button>
                  <h3 className="modal-title text-center contact-modal">Contact Us</h3>
                </div>
                <div className="modal-body" style={{height: '100%'}}>
                <h6 style={{display: ackMsg ? '': 'none'}} className="ackSec">
                    <span className={`subAck ${ackMsg}`}>{ackMsg === 'ackSuccess' ? ackSuccess : ackFailure}</span>
                  </h6>
                  <Form className={`${closeForm ? "force-hide" : "force-show"}`}>
                    {/* <Row>
                      <Col md="4" xs="12">
                        <h3>Select your store</h3> : 
                      </Col>
                      <Col md="8" xs="12">
                        <Row>
                          <Col><label className="gen-contact-label" for="distance">Search within:</label><input type="number" id="distance" name="distance" min="1" max="100" value="30"/><span>miles of</span></Col>
                          <Col><label className="gen-contact-label" for="distance">Zip Code</label><input type="text" id="zipcode" name="zipcode" value="91360"/></Col>
                          <Button type="submit" className="btn btn-primary pull-right" color="danger" onClick={(e) => e.preventDefault()}>
                            Search
                          </Button>
                        </Row>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md="4" xs="12">
                        <h4>Select your store :</h4>
                      </Col>
                      <Col md="6" xs="12">
                        <Row>
                          <Col>
                            <Row>
                              <label className="gen-contact-label" for="distance">Search within:</label>
                            </Row>
                            <Row>
                              <Col className="align-center">
                              <FormGroup>
                              <Input
                                  type="number"
                                  value={radius}
                                  invalid={radiusInValid === 'invalid'}
                                  name="custRadius"
                                  onBlur={(e) => validateFileds(e)}
                                  onChange={(e) => setRadius(e.target.value)}
                                  min="1"
                                  max="100"
                                  size="3"
                                />
                                <FormFeedback>Please enter a valid search radius within 1-100</FormFeedback>
                                </FormGroup>
                                {/* <input type="number" id="distance" name="distance" min="1" max="100" value="30"/> */}
                              </Col>
                            </Row>
                          </Col>
                          <span style={{marginTop : '2.5rem'}}>miles of</span>
                          <Col>
                            <Row>
                              <label className="gen-contact-label" for="distance">Zip Code</label>
                            </Row>
                            <Row>
                              <Col className="align-center">
                              <FormGroup>
                              <Input
                                  type="text"
                                  value={zipCode}
                                  invalid={zipInValid === 'invalid'}
                                  name="custZip"
                                  onBlur={(e) => validateFileds(e)}
                                  onChange={(e) => {handleChange(e); setZipCode(e.target.value)}}
                                  size="10"
                                />
                                <FormFeedback>Please enter a valid zipcode</FormFeedback>
                                </FormGroup>
                                {/* <input type="text" id="zipcode" name="zipcode" value="91360" size="10"/> */}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="2" xs="12" className="align-center mt-1r">
                        <Button type="submit" className="btn btn-primary" color="danger" onClick={(e) => searchStores(e)} disabled={!storeFormValid}>
                          Search
                        </Button>
                      </Col>
                    </Row>
                    {
                      storeResultSection != null && storeResultSection ? 
                      <Row className="mt-10">
                          {storeResultSection}
                      </Row>
                      :
                      <div>

                      </div>
                    }
                    <hr />
                    <div style={{
                        opacity: !storeId ? 0.35 : 1,
                        pointerEvents: !storeId ? "none" : "initial"
                      }}>
                    <Row>
                      <Col md="6" xs="12">
                      <FormGroup>
                        <Input
                          placeholder="Name"
                          type="text"
                          invalid={nameInValid === 'invalid'}
                          value={uName}
                          name="custName"
                          onBlur={(e) => validateFileds(e)}
                          onChange={(e) => setUName(e.target.value)}
                        />
                        <FormFeedback>Please enter a your name</FormFeedback>
                      </FormGroup>
                      </Col>
                      <Col md="6" xs="12">
                        <FormGroup>
                          <Input
                            placeholder="Interested in..."
                            type="text"
                            value={interests}
                            onChange={(e) => setInterests(e.target.value)}
                          />
                          <FormFeedback>Please let us know what you are interested in</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md="6" xs="12">
                            <FormGroup>
                              <Input
                                placeholder="Email address"
                                type="email"
                                invalid={emailInValid === 'invalid'}
                                name="custEmail"
                                value={email}
                                onBlur={(e) => validateFileds(e)}
                                onChange={(e) => {handleChange(e); setEmail(e.target.value)}}
                              />
                          </FormGroup>
                        </Col>
                        <Col md="6" xs="12">
                          <FormGroup>
                            <Input
                                    type="number"
                                    name="custPhone"
                                    id="phoneNumber"
                                    placeholder="Phone number"
                                    invalid={phoneInvalid === 'invalid'}
                                    onBlur={(e) => validateFileds(e)}
                                    value={phone}
                                    onChange={(e) => {handleChange(e); setPhone(e.target.value)}}
                                    />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <Col className="align-center">
                        <div style={{fontWeight: '500', fontWeight: '12px'}} class={`fw500 invalid-feedback ${phoneInvalid === 'invalid' || emailInValid === 'invalid' ? 'force-show' : ''}`}>Please enter a valid phone number or email address</div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md="12" xs="12">
                        <FormGroup>
                          <Input type="textarea" name="custMsg" id="message" 
                          placeholder="Please type your message here..."
                          value={message}
                          invalid={msgInValid === 'invalid'}
                          onChange={(e) => {setMessage(e.target.value)}}/>
                          <FormFeedback>Please enter your message</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="smsConsent">{config.contactConsent}</Label>
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button type="reset" className="btn-round pull-left" color="default" onClick={() => {setCtModal(false); clearFormFields();}}>
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button type="submit" className="btn-round pull-right" color="danger" onClick={(e) => handleSubmit(e)} disabled={!formValid}>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                    </div>
                  </Form>
                </div>
                        </Modal>
        </>
    )
}