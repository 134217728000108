import React from 'react';
import {
  FormGroup, Form, Input, Label, Col, Row
} from "reactstrap";
import ActionButtonsFrag from './frags/ActionButtonsFrag.js';
import { getToggledElements } from '../../../gc-comps/utility/CommonHandlers.js';

const lessonSelectPrefferedDay = new Event('LESSON_SELECT_PREFFERED_DAY');

const StepTenGeneral = ({step, setStep, user, setUser, optional, setRLoading}) => {
    const[btnText, setBtnText] = React.useState(optional && (!user.daysOfWeek || user.daysOfWeek.length < 1) ? "Skip" : "Next");
    const handleUser = ({ target }) => {
      var daysOfWeek = getToggledElements(user.daysOfWeek, target.value);
        setUser({
          type: 'LESSON_DAYS_OF_WEEK',
          payload: { [target.name]: daysOfWeek },
        });
        if(daysOfWeek.length > 0){
          setBtnText("Next");
        }else{
          setBtnText("Skip");
        }
        document.dispatchEvent(lessonSelectPrefferedDay);
      }
    const handleContinue = (e) => {
        e.preventDefault();
        if(!optional && !user.daysOfWeek){
          return;
        }
        if(e.target.name.toLowerCase() === 'skip'){
          setStep(step+1);
        }else{
          setRLoading(true);
          setTimeout(() => {
            setRLoading(false);
            setStep(step+1);
          }, 500);
        }
    }
    const daysOfWeekArr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    return(
        <Form>
          <div className="reg-modal-form">
            <Row className="mx-auto">
              {daysOfWeekArr.map((value, index) => {
                return (
                  <Col md="6" xs="6" sm="6">
                    <FormGroup check key={`daysOfWeek-fg-${index}`}>
                      <Label check key={`daysOfWeek-lbl-${index}`} className="dow-lbl">
                        <Input
                          id={`daysOfWeek-${index}`}
                          key={`daysOfWeek-${index}`}
                          name="daysOfWeek"
                          type="checkbox"
                          value={value}
                          onChange={handleUser}
                          checked={user.daysOfWeek.includes(value)}
                        />
                        {value} <span className="form-check-sign" />
                      </Label>
                    </FormGroup>
                  </Col>
                )
              })}
            </Row>
          </div>  
          <ActionButtonsFrag setStep={setStep} step={step} handleContinue={handleContinue} continueBtnText={btnText}/>
        </Form>
    );
} 

export default StepTenGeneral;