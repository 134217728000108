import {
    CardBody,
    Collapse,
    Label,
    FormGroup,
    Input,
  } from "reactstrap";
import React from "react";

class TermsWrapper extends React.Component {

    constructor()
    {
        super();
        let lessonModeLS ='';
        let instrumentLS ='';
        if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).lessonMode)
        {
            lessonModeLS=JSON.parse(localStorage.getItem('userSelections')).lessonMode;
        }
        if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).instrument)
        {
            instrumentLS=JSON.parse(localStorage.getItem('userSelections')).instrument;
        }
        if(Array.isArray(lessonModeLS))
        {
           this.state = {
               lessonsTypeBoxes:lessonModeLS,
               instrument:instrumentLS
           }
        }
        else
        {
            this.state = {
                lessonsTypeBoxes:[lessonModeLS],
                instrument:[instrumentLS]
            }
        }
    }

    render() {
        let datafilters = [];

        if (
            // checking for when component gets the aggregation results
            this.props.aggregations
            && this.props.aggregations.datafilters
            && this.props.aggregations.datafilters.buckets.length
        ) {
            datafilters = this.props.aggregations.datafilters.buckets.map(datafilters => datafilters.key);
        }

        return (
            <div>
                <Collapse isOpen={true}>
                    <CardBody>
                        {datafilters.map((datafilter, index) =>
                            <FormGroup check>
                              <Label check>
                                <Input value={datafilter} type={this.props.type} name={this.props.name} id={this.props.name+datafilter} checked={this.state.lessonsTypeBoxes.includes(datafilter)}
                                onChange={ (event) => {
                                    const checkedBoxesCnst = document.querySelectorAll('input[name='+this.props.name+']:checked');
                                    var checkedBoxesArray = Array.from(checkedBoxesCnst);
                                    var checkedBoxes = [];
                                    var checkedBoxesVal = [];
                                    var checkedBoxesAct = [];
                                    var elkey = this.props.elastickey+'.keyword';
                                    for(let val of checkedBoxesArray)
                                    {
                                        if(val.value==='In-Store')
                                        {
                                            checkedBoxesVal[checkedBoxesVal.length]="In Person";
                                        }
                                        else
                                        {
                                            checkedBoxesVal[checkedBoxesVal.length]=val.value;
                                        }
                                        checkedBoxes[checkedBoxes.length]=val.value;
                                        if(JSON.parse(localStorage.getItem('userSelections')).instrument && JSON.parse(localStorage.getItem('userSelections')).instrument.length>0
                                            && !JSON.parse(localStorage.getItem('userSelections')).instrument.includes('Not sure'))
                                        {
                                            for(var ins of JSON.parse(localStorage.getItem('userSelections')).instrument)
                                            {
                                                if(val.value==='Online')
                                                {
                                                    checkedBoxesAct[checkedBoxesAct.length] = val.value + ' ' + ins;
                                                }
                                                else
                                                {
                                                    checkedBoxesAct[checkedBoxesAct.length] = val.value + ' ' + ins+' Lesson';
                                                    checkedBoxesAct[checkedBoxesAct.length] = val.value + ' ' + ins;
                                                }
                                            }
                                            elkey = 'activityFull.keyword';
                                        }
                                        else
                                        {
                                            checkedBoxesAct[checkedBoxesAct.length] = val.value;
                                        }
                                    }
                                    var query = {
                                        "query": {
                                            "terms": { [elkey] : checkedBoxesAct }
                                        }
                                    };

                                    if(checkedBoxes && checkedBoxes.length===0)
                                    {
                                        query = {
                                            query: {
                                                "match_all": {}
                                            }
                                        };
                                    }

                                    this.setState({lessonsTypeBoxes:checkedBoxes});
                                    var ls = JSON.parse(localStorage.getItem('userSelections'));
                                    Object.keys(ls).forEach((key) => {
                                        if('lessonMode'==key)
                                        {
                                            ls[key]=checkedBoxes;
                                        }
                                    });
                                    localStorage.setItem('userSelections', JSON.stringify(ls));
                                    this.props.setQuery({
                                        query,
                                        value: checkedBoxesVal
                                    })

                                    if(document.getElementById('prxdow'))
                                     {
                                         document.getElementById('prxdow').click();
                                     }
                                     if(document.getElementById('prxpt'))
                                     {
                                         document.getElementById('prxpt').click();
                                     }
                                }}/>
                                {datafilter=='In-Store'?'In Person':datafilter} <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                        )}

                        <FormGroup check>
                          <Label check>
                            <Input value="prx" type="checkbox" name="prxlt" id="prxlt" style={{display:'none'}}
                            onChange={ (event) => {
                                const checkedBoxesCnst = document.querySelectorAll('input[name='+this.props.name+']:checked');
                                var checkedBoxesArray = Array.from(checkedBoxesCnst);
                                var checkedBoxes = [];
                                var checkedBoxesVal = [];
                                var checkedBoxesAct = [];
                                var elkey = this.props.elastickey+'.keyword';
                                for(let val of checkedBoxesArray)
                                {
                                    if(val.value==='In-Store')
                                    {
                                        checkedBoxesVal[checkedBoxesVal.length]="In Person";
                                    }
                                    else
                                    {
                                        checkedBoxesVal[checkedBoxesVal.length]=val.value;
                                    }
                                    checkedBoxes[checkedBoxes.length]=val.value;
                                    if(JSON.parse(localStorage.getItem('userSelections')).instrument && JSON.parse(localStorage.getItem('userSelections')).instrument.length>0)
                                    {
                                        for(var ins of JSON.parse(localStorage.getItem('userSelections')).instrument)
                                        {
                                            if(val.value==='Online')
                                            {
                                                checkedBoxesAct[checkedBoxesAct.length] = val.value + ' ' + ins;
                                            }
                                            else
                                            {
                                                checkedBoxesAct[checkedBoxesAct.length] = val.value + ' ' + ins+' Lesson';
                                                checkedBoxesAct[checkedBoxesAct.length] = val.value + ' ' + ins;
                                            }
                                        }
                                        elkey = 'activityFull.keyword';
                                    }
                                    else
                                    {
                                        checkedBoxesAct[checkedBoxesAct.length] = val.value;
                                    }
                                }
                                var query = {
                                    "query": {
                                        "terms": { [elkey] : checkedBoxesAct }
                                    }
                                };

                                if(checkedBoxes && checkedBoxes.length===0)
                                {
                                    query = {
                                        query: {
                                            "match_all": {}
                                        }
                                    };
                                }

                                this.setState({lessonsTypeBoxes:checkedBoxes});
                                var ls = JSON.parse(localStorage.getItem('userSelections'));
                                Object.keys(ls).forEach((key) => {
                                    if('lessonMode'==key)
                                    {
                                        ls[key]=checkedBoxes;
                                    }
                                });
                                localStorage.setItem('userSelections', JSON.stringify(ls));
                                this.props.setQuery({
                                    query,
                                    value: checkedBoxesVal
                                })
                            }}/>
                          </Label>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </div>
        )
    }
}

export default TermsWrapper;