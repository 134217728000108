import React from 'react';
import {
  FormGroup, Form, Input, Label
} from "reactstrap";
import ActionButtonsFrag from './frags/ActionButtonsFrag.js';
import { useHistory } from "react-router-dom";
import { getToggledElements } from '../../../gc-comps/utility/CommonHandlers.js';

const lessonSelectPrefferedTime = new Event('LESSON_SELECT_PREFFERED_TIME');

const StepElevenGeneral = ({step, setStep, user, setUser, optional, setRLoading}) => {
  const[btnText, setBtnText] = React.useState(optional && (!user.timeOfDay || user.timeOfDay.length < 1) ? "Skip" : "Next");
    const handleUser = ({ target }) => {
      var timeOfDay = getToggledElements(user.timeOfDay, target.value);
        setUser({
          type: 'LESSON_TIME',
          payload: { [target.name]: timeOfDay },
        });
        if(timeOfDay.length > 0){
          setBtnText("Next");
        }else{
          setBtnText("Skip");
        }
        document.dispatchEvent(lessonSelectPrefferedTime);
      }
      const history = useHistory();

      const routeChange = () =>{ 
        let path = `inst-listing`; 
        history.push(path);
      }
    const handleContinue = (e) => {
        e.preventDefault();
        setStep(step+1);
        // localStorage.setItem('userSelections', JSON.stringify(user));
       // routeChange();
        // if(!optional && !user.timeOfDay){
        //   return;
        // }
        // if(e.target.name.toLowerCase() === 'skip'){
        //   setStep(step+1);
        // }else{
        //   setRLoading(true);
        //   setTimeout(() => {
        //     setRLoading(false);
        //     setStep(step+1);
        //   }, 500);
        // }
    }
    const timeOfDayaArr = [
      {
        label : 'Morning (11AM–Noon)',
        val : '1'
      },
      {
        label : 'Afternoon (Noon–5PM)',
        val : '2'
      },
      {
        label : 'Evening (After 5PM)',
        val : '3'
      }
    ];
    return(
        <Form>
          <div className="reg-modal-form">
            {timeOfDayaArr.map((value, index) => {
              return (
                <FormGroup check key={`timeOfDay-fg-${index}`}>
                  <Label check key={`timeOfDay-lbl-${index}`} className="dow-lbl">
                    <Input
                      id={`timeOfDay-${index}`}
                      key={`timeOfDay-${index}`}
                      name="timeOfDay"
                      type="checkbox"
                      value={value.val}
                      onChange={handleUser}
                      checked={user.timeOfDay.includes(value.val)}
                    />
                    {value.label} <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              )
            })}
          </div>  
          <ActionButtonsFrag setStep={setStep} step={step} handleContinue={handleContinue} continueBtnText={btnText}/>
        </Form>
    );
} 

export default StepElevenGeneral;