import {
    Button,
    CardBody,
    Collapse,
    FormGroup,
    Input,
    Row,
    Col
  } from "reactstrap";
import React from "react";
import Geocode from "react-geocode";

class LocationWrapper extends React.Component {
    constructor()
    {
        super();
        let zipcodeLS = '';
        if(JSON.parse(localStorage.getItem('userSelections')) && JSON.parse(localStorage.getItem('userSelections')).zipCode)
        {
            zipcodeLS=JSON.parse(localStorage.getItem('userSelections')).zipCode;
        }
        if(Array.isArray(zipcodeLS))
        {
           this.state = {
               zipcodeinput:zipcodeLS
           }
        }
        else
        {
            this.state = {
                zipcodeinput:[zipcodeLS]
            }
        }
    }

    updateInput(event){
        this.setState({zipcodeinput: event.target.value});
    }

    onCheckboxBtnClick = (event) => {
        var zipcodeinput = this.state.zipcodeinput;

        var milesslider = document.getElementById('milesslider');

        Geocode.setApiKey('AIzaSyDTp_TxjEbRE8pxSPJjX69E5RGREXA08GA');
        Geocode.setLanguage("en");
        Geocode.setRegion("us");
        Geocode.setLocationType("ROOFTOP");
        Geocode.enableDebug();
        Geocode.fromAddress(zipcodeinput).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;

            var query = {
                "query": {
                    "geo_distance": {
                        "distance": milesslider.noUiSlider.get()+'mi',
                        "storelatlong": {
                          "lat": lat,
                          "lon": lng
                      }
                    }
                }
            };

            var ls = JSON.parse(localStorage.getItem('userSelections'));
            Object.keys(ls).forEach((key) => {
                if('zipCode'==key)
                {
                    ls[key]=zipcodeinput;
                    ls['latitude']=lat;
                    ls['longitude']=lng;
                }
            });
            this.props.handleUpdate(lat,lng);
            localStorage.setItem('userSelections', JSON.stringify(ls));

            this.props.setQuery({
                query,
                value: [milesslider.noUiSlider.get(), lat, lng]
            })
          },
          (error) => {
            console.error(error);
          }
        );
    }

    render() {
        return (
            <div>
                <Collapse isOpen={true}>
                    <CardBody>
                        <FormGroup>
                        <Row>
                        <Col md="9">
                        <Input
                            className="simple"
                            placeholder="Zip Code"
                            type="text"
                            style={{marginBottom:50}}
                            name="zipcodeinput"
                            value={this.state.zipcodeinput}
                            onChange={this.updateInput.bind(this)}
                        />
                        </Col>
                        <Col md="3">
                        <Button name="zipsearchbutton" outline size="regular" color="danger" type="button" onClick={this.onCheckboxBtnClick.bind(this)}>
                          Go
                        </Button>
                        </Col>
                        </Row>
                        <div className="slider slider-danger" id="milesslider" style={{paddingLeft:20}}/>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </div>
        )
    }
}

export default LocationWrapper;